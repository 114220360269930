import httpClient from 'api/http-client';

export interface Response {
    result: string;
}

const updateContact = async (payload: {
    contactId: number;
    aiEnabled?: boolean;
    aiEnabledReplySmsCall?: boolean;
}): Promise<Response> => {
    const { data } = await httpClient.post<Response>('/api/update-db-contact', payload);
    return data;
};

export default updateContact;
