import axios from 'axios';
import config from 'utils/config';

const client = axios.create({
    baseURL: config.SERVER_URL,
    timeout: 12000,
    validateStatus: () => true,
    withCredentials: true
});

export default client;
