import httpClient from 'api/http-client';
import { HubspotContactLong } from 'types/hubspot';

export interface Response {
    result: HubspotContactLong;
}

const getHubspotContact = async (payload: { contactId: string; properties: string[] }): Promise<HubspotContactLong> => {
    const { data } = await httpClient.post<HubspotContactLong>(`/api/hubspot-contact`, payload);
    return data;
};

export default getHubspotContact;
