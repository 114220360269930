import httpClient from 'api/http-client';

export interface Response {
    result: string;
}

const updateContactIntakeStatus = async (payload: {
    contactId: number;
    intakeStatus: string | null;
}): Promise<Response> => {
    const { data } = await httpClient.post<Response>('/api/update-contact-intake-status', payload);
    return data;
};

export default updateContactIntakeStatus;
