import httpClient from 'api/http-client';
import { DbContact } from 'types/dbContact';

export interface Response {
    result: DbContact[];
    total: number;
    aiEnabled: boolean;
    intakeStatusArray: string[];
    aiEnabledAutoTakeContact: boolean;
    autoTakeContactRatio: number;
}

export const NORMAL_AI_STATUS = 'Normal';

export type FilterModelItemInPayload = {
    colId: string;
    filterType: 'text' | 'date';
    type: 'contains' | 'inRange';
    filter?: string | null;
    dateFrom?: number | null;
    dateTo?: number | null;
};

const getContacts = async (payload: {
    search: string | null;
    page: number;
    pageSize: number;
    sortFields: string[];
    sortOrders: ('asc' | 'desc')[];
    filters: FilterModelItemInPayload[];
}): Promise<Response> => {
    const { data } = await httpClient.post<Response>('/api/get-db-contact', payload);
    return data;
};

export default getContacts;
