import getHubspotContacts from 'api/getHubspotContacts';
import Loader from 'components/Loader';
import SpinLoader from 'components/ui/spinLoader';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { HubspotShortContact } from 'types/hubspot';
import Contacts from './Contacts/Contacts';
import AiConfigComp from './AiConfig';

function MainPage() {
    const [contacts, setContacts] = useState<HubspotShortContact[]>([]);
    const [after, setAfter] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [search, setSearch] = useState<string | null>(null);
    const [selectedTab, setSelectedTab] = useState('db');

    const fetchContacts = async () => {
        setIsLoading(true);
        const res = await getHubspotContacts({ search: search, after: null });
        setContacts(res.contacts);
        setAfter(res.after);
        setIsLoading(false);
    };

    useEffect(() => {
        fetchContacts();
    }, []);

    async function onLoadMore() {
        setIsLoading(true);
        const res = await getHubspotContacts({ search, after });
        setContacts([...contacts, ...res.contacts]);
        setAfter(res.after);
        setIsLoading(false);
    }

    if (isLoading) {
        return (
            <div className="flex h-[100vh] w-[100vw] items-center justify-center">
                <Loader />
            </div>
        );
    }

    return (
        <div className="h-full w-full px-4 sm:px-6 lg:px-8">
            <div className="h-[5rem]">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-base font-semibold leading-6 text-gray-900">Contacts</h1>
                    </div>
                    {selectedTab === 'hubspot' && (
                        <div className="mt-4 sm:ml-16 sm:mt-0">
                            <div>
                                <div className="mt-2 flex items-center gap-2">
                                    <div className="rounded border p-1">
                                        <input
                                            value={search || ''}
                                            type="text"
                                            placeholder="Search for contacts"
                                            onChange={e => {
                                                if (e.target.value === '') {
                                                    setSearch(null);
                                                } else {
                                                    setSearch(e.target.value);
                                                }
                                            }}
                                        />
                                    </div>
                                    <button
                                        type="button"
                                        className="rounded-md border border-gray-300 bg-gray-100 px-2 py-1 text-gray-800"
                                        disabled={isSubmitting}
                                        onClick={async () => {
                                            setIsSubmitting(true);
                                            await fetchContacts();
                                            setIsSubmitting(false);
                                        }}
                                    >
                                        {isSubmitting && <SpinLoader className="mr-2 h-4 w-4 animate-spin" />}
                                        Search
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="flex gap-4">
                    <button
                        className={`rounded px-2 py-1 ${selectedTab === 'db' ? 'bg-blue-300' : 'bg-gray-200'}`}
                        onClick={() => setSelectedTab('db')}
                    >
                        AI Handled Contacts
                    </button>
                    <button
                        className={`rounded px-2 py-1 ${selectedTab === 'ai-config' ? 'bg-blue-300' : 'bg-gray-200'}`}
                        onClick={() => setSelectedTab('ai-config')}
                    >
                        AI Automatic Followup Rules
                    </button>
                    <button
                        className={`rounded px-2 py-1 ${selectedTab === 'hubspot' ? 'bg-blue-300' : 'bg-gray-200'}`}
                        onClick={() => setSelectedTab('hubspot')}
                    >
                        Hubspot Contacts
                    </button>
                </div>
            </div>

            {selectedTab === 'hubspot' && (
                <div className="mt-4 flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                            >
                                                First Name
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                            >
                                                Last Name
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                            >
                                                Email
                                            </th>
                                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                <span className="sr-only">Edit</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                        {contacts.map(contact => (
                                            <tr key={contact.id}>
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                    {contact.properties.firstname}
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    {contact.properties.lastname}
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    {contact.properties.email}
                                                </td>
                                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                    <button type="button">
                                                        <Link to={`/contacts/${contact.id}`}>View</Link>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {selectedTab === 'db' && <Contacts />}
            {selectedTab === 'ai-config' && <AiConfigComp />}
            {after && selectedTab === 'hubspot' && (
                <div className="mt-10 text-center">
                    {/* <Button type="button" variant="secondary" onClick={onLoadMore}>
                        {isLoading && <SpinLoader className="w-4 h-4 animate-spin mr-2" />}
                        Load More Contacts
                    </Button> */}
                </div>
            )}
        </div>
    );
}

export default MainPage;
