import httpClient from './http-client';

export interface Response {
    result: string;
    message: string;
}

const resetPassword = async (resetPasswordPayLoad: { oldPassword: string; newPassword: string }): Promise<Response> => {
    const { data } = await httpClient.post<Response>('/api/reset-password', resetPasswordPayLoad);
    return data;
};

export default resetPassword;
