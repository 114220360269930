import React from 'react';

interface Props {
    className?: string;
}

function Loader({ className }: Props) {
    return <img className={className} alt="Loader" src="/images/superpanel_loading.gif" />;
}

Loader.defaultProps = {
    className: 'w-20 h-20'
};

export default Loader;
