import { mountStoreDevtool } from 'simple-zustand-devtools';
import { create } from 'zustand';
import { User } from 'types/user';

export interface State {
    user: null | User;
    setState: (newState: Partial<State> | null) => State;
}

const useUserStore = create<State>((set, get) => ({
    user: null,
    setState: (newState: Partial<State> | null) => {
        if (newState === null) {
            set({ user: null });
            return get();
        } else {
            set(state => {
                return { ...state, ...newState };
            });
            return get();
        }
    }
}));

if (process.env.NODE_ENV === 'development') {
    mountStoreDevtool('User', useUserStore);
}
export default useUserStore;
