import React, { useState } from 'react';
import { Box, Modal } from '@mui/material';
import { TypeContactDetail } from 'api/get-contact-detail';
import { Label } from 'components/ui/label';
import SendSmsForm from './CallAndSMS/SendSmsForm';

function AssistantMessageView(props: { content: string; datetime: string; status: string | null }) {
    return (
        <div className="w-fit min-w-28 text-sm">
            <Label className="capitalize text-gray-500">
                Assistant ({props.datetime}) <b className="rounded bg-slate-300 px-1">{props.status}</b>:
            </Label>
            <p className="w-fit min-w-28 rounded bg-green-200 p-1 text-sm">{props.content}</p>
        </div>
    );
}

function SystemMessageView(props: { content: string; datetime: string }) {
    return (
        <div className="w-fit min-w-28 text-sm">
            <Label className="capitalize text-gray-500">System ({props.datetime}):</Label>
            <p className="w-fit min-w-28 rounded bg-orange-200 p-1 text-sm">{props.content}</p>
        </div>
    );
}

function UserMessageView(props: {
    content: string;
    datetime: string;
    smsFileUrl: string | null;
    onImageClick: (url: string) => void;
}) {
    return (
        <div className="flex flex-col">
            <div className="flex justify-end">
                <div className="min-w-28 text-sm">
                    <Label className="capitalize text-gray-500">User ({props.datetime}):</Label>
                    {props.content && <p className="min-w-28 rounded bg-blue-200 p-1 text-sm">{props.content}</p>}
                </div>
            </div>
            <div className="flex justify-end">
                {props.smsFileUrl && (
                    <img
                        src={props.smsFileUrl}
                        alt="SMS Media File"
                        className="mt-2 cursor-pointer rounded"
                        onClick={() => props.onImageClick(props.smsFileUrl ?? '')}
                    />
                )}
            </div>
        </div>
    );
}

function ContactDetailSms(props: { contactInfo: TypeContactDetail }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImageUrl, setSelectedImageUrl] = useState<string | null>(null);

    const getDatetime = (timestamp: number) => {
        const date = new Date(timestamp);
        return date.toLocaleString();
    };

    const handleImageClick = (url: string) => {
        setSelectedImageUrl(url);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedImageUrl(null);
    };

    return (
        <div className="flex h-[calc(100vh-8rem)] w-full flex-col justify-between gap-4 px-2">
            <div className="flex h-full flex-col gap-3 overflow-auto rounded-md border p-2">
                {props.contactInfo.smsInfo.map(sms => {
                    return (
                        <div key={sms.id}>
                            {sms.role === 'user' ? (
                                <UserMessageView
                                    content={sms.content}
                                    datetime={getDatetime(sms.timestamp)}
                                    smsFileUrl={sms.smsFileUrl}
                                    onImageClick={handleImageClick}
                                />
                            ) : sms.role === 'assistant' ? (
                                <AssistantMessageView
                                    content={sms.content}
                                    datetime={getDatetime(sms.timestamp)}
                                    status={sms.status}
                                />
                            ) : (
                                <SystemMessageView content={sms.content} datetime={getDatetime(sms.timestamp)} />
                            )}
                        </div>
                    );
                })}
            </div>
            <div className="">
                {<SendSmsForm hubspotContactId={String(props.contactInfo.generalInfo.hubspotContactId)} />}
            </div>

            <Modal open={isModalOpen} onClose={handleCloseModal}>
                <Box
                    sx={{
                        p: 4,
                        backgroundColor: 'white',
                        margin: 'auto',
                        marginTop: '10%',
                        width: '80%',
                        minHeight: '600px',
                        borderRadius: '8px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        overflow: 'auto'
                    }}
                >
                    <div className="m-4 h-[200px]">
                        {selectedImageUrl && (
                            <img
                                src={selectedImageUrl}
                                alt="SMS Media File"
                                style={{
                                    width: 'auto',
                                    height: '600px'
                                }}
                            />
                        )}
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

export default ContactDetailSms;
