import React, { useState } from 'react';
import { TextField, Button, Card, CardContent, CardActions, Typography, Box, Snackbar, Alert } from '@mui/material';
import resetPassword from 'api/reset-password';

const SettingsPage = () => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const handleSubmit = async (e: React.FormEvent) => {
        // stop refreshing the page when submitting the form
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            setMessage('New password and confirm password do not match.');
            setOpenSnackbar(true);
            return;
        }

        if (newPassword.length < 6) {
            setMessage('Password must be at least 6 characters long.');
            setOpenSnackbar(true);
            return;
        }

        try {
            const res: { result: string; message: string } = await resetPassword({ oldPassword, newPassword });
            if (res.result === 'error') {
                setMessage(res.message);
                setOpenSnackbar(true);
            }

            if (res.result === 'success') {
                setMessage(res.message);
                setOpenSnackbar(true);
                setOldPassword('');
                setNewPassword('');
                setConfirmPassword('');
            }
        } catch (error) {
            setMessage('An error occurred. Please try again later.');
            setOpenSnackbar(true);
        }
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <Box sx={{ maxWidth: 400, margin: 'auto', mt: 8 }}>
            <Card>
                <CardContent>
                    <Typography variant="h5" component="div" gutterBottom>
                        Reset Password
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Old Password"
                            type="password"
                            value={oldPassword}
                            onChange={e => setOldPassword(e.target.value)}
                            required
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label="New Password"
                            type="password"
                            value={newPassword}
                            onChange={e => setNewPassword(e.target.value)}
                            required
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Confirm New Password"
                            type="password"
                            value={confirmPassword}
                            onChange={e => setConfirmPassword(e.target.value)}
                            required
                        />
                        <CardActions sx={{ justifyContent: 'flex-end', padding: '16px 0 0' }}>
                            <Button type="submit" variant="contained" color="primary">
                                Reset
                            </Button>
                        </CardActions>
                    </form>
                </CardContent>
            </Card>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={message.includes('successfully') ? 'success' : 'error'}
                    sx={{ width: '100%' }}
                    elevation={6}
                    variant="filled"
                >
                    {message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default SettingsPage;
