import React from 'react';
import ContactDetailBasic from './ContactInfoBasic';
import ContactDetailCall from './ContactInfoCall';
import ContactDetailSms from './ContactInfoSms';
import { TypeContactDetail } from 'api/get-contact-detail';
import CallAgenda from './CallAndSMS/CallAgenda';
import CreateAICallButton from './CallAndSMS/CreateAiCallButton';

function ContactDetail(props: { selectedTab: string; contactId: string; contactInfo: TypeContactDetail }) {
    return (
        <div className="grid h-full grid-cols-3 gap-3">
            <div className="">
                {props.selectedTab === 'tab1' ? <ContactDetailBasic contactInfo={props.contactInfo} /> : <CallAgenda />}
            </div>
            <div className="">
                <div className="flex items-center justify-between p-2">
                    <span className="text-lg font-medium">Calls</span>
                    {<CreateAICallButton contactId={props.contactId} onCall={() => {}} />}
                </div>
                <ContactDetailCall contactInfo={props.contactInfo} />
            </div>
            <div className="flex h-full flex-col">
                <div className="flex justify-center text-lg font-medium">Sms</div>
                <div className="h-full overflow-auto">
                    <ContactDetailSms contactInfo={props.contactInfo} />
                </div>
            </div>
        </div>
    );
}

export default ContactDetail;
