import React from 'react';
import './SuperpanelIcon.css';

function SuperpanelIcon(props: { className: string }) {
    return (
        <svg
            className={props.className}
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle id="yellow" cx="19.7093" cy="8.94949" r="3.94949" fill="#FFC74D" />
            <circle id="green" cx="11.9495" cy="23.6409" r="3.94949" fill="#1DBC86" />
            <path
                id="dark-blue"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.6508 5.00293C10.9589 5.11334 8 8.14085 8 11.8594C8 15.578 10.9589 18.6055 14.6508 18.716V5.00293Z"
                fill="#2F80ED"
            />
            <path
                id="light-blue"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.0074 13.8701C20.6993 13.9805 23.6582 17.008 23.6582 20.7266C23.6582 24.4452 20.6993 27.4727 17.0074 27.5831V13.8701Z"
                fill="#96B7F5"
            />
        </svg>
    );
}

export default SuperpanelIcon;
