/* eslint-disable max-classes-per-file */

export class SuperpanelAPIError extends Error {
    static code = '';

    code: string;

    link: string | null = null;

    displayErrorMessage: string;

    constructor(displayErrorMessage: string) {
        super();
        this.code = (this.constructor as typeof SuperpanelAPIError).code;
        this.displayErrorMessage = displayErrorMessage;
    }
}
const errors = {
    SuperpanelAPIError
};

export default errors;
