import httpClient from './http-client';
import { IMessage } from 'types/ai';

export type CallInfo = {
    id: number;
    timestamp: number;
    status: string;
    externalUrl: string | null;
    summary: string | null;
    recordingURL: string | null;
    conversations: IMessage[];
};

export type ISlotV1 = {
    value: boolean | number | string | Date | null;
    state: 'complete' | 'need-more-data' | 'n/a' | 'initial';
    reason: string | null;
    msg_for_future: null | string;
};

export type ISlotWithId = ISlotV1 & { slot_id: string };

export type CollectionInfo = ISlotWithId & { integration: string | null; dataType: string | null };

export type TypeContactDetail = {
    collectionInfo: CollectionInfo[];
    callInfo: CallInfo[];
    smsInfo: {
        id: number;
        timestamp: number;
        role: 'user' | 'assistant' | 'system';
        content: string;
        status: string | null;
        smsFileUrl: string | null;
    }[];
    jobInfo: {
        id: number;
        name: string;
        status: string;
        scheduled: string;
    }[];
    generalInfo: {
        id: number;
        hubspotContactId: number;
        nextStep: string | null;
        nextStepDatetime: number | null;
        nextStepReason: string | null;
        nextStepAutomatic: {
            nextStep: 'doCall' | 'doSms' | 'doNothing';
            reason: string;
            scheduledAt: null | number;
        };
        contactFriendlyHours: {
            timezone: string | null;
            start: number;
            end: number;
        };
        intakeStatus: null | string;
        intakeStatusReason: null | string;
        intakeCompletionScore: number;
        aiEnabled: boolean;
        aiEnabledReplySmsCall: boolean;
        hubspotUrl: string;
        intakeStatusArray: string[];
        aiDisabledReason: string | null;
        aiDisabledReplySmsCallReason: string | null;
    };
};

const getContactDetail = async (getContactDetailPayload: { contactId: string }): Promise<TypeContactDetail> => {
    const { data } = await httpClient.post<TypeContactDetail>('/api/get-contact-detail', getContactDetailPayload);
    return data;
};

export default getContactDetail;
