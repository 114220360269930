import React from 'react';
import { millionSecondsToDhms } from 'lib/seconds-to-days-hours-mins';

interface Props {
    data: { nextStepDetailScheduledAt: number | null };
}

function ScheduledAtComponent({ data }: Props) {
    if (data.nextStepDetailScheduledAt) {
        const timeFromNow = data.nextStepDetailScheduledAt - Date.now();
        const timeFromNowObj = millionSecondsToDhms(timeFromNow);
        let color = '';
        if (timeFromNow < 30 * 60 * 1000) {
            color = 'text-green-500';
        }
        if (
            timeFromNowObj.dDisplay === '' &&
            timeFromNowObj.hDisplay === '' &&
            timeFromNowObj.mDisplay === '' &&
            timeFromNowObj.sDisplay === ''
        ) {
            return <div className={`${color}`}>now</div>;
        }
        return (
            <div className={`${color}`}>
                in {timeFromNowObj.dDisplay} {timeFromNowObj.hDisplay} {timeFromNowObj.mDisplay}
            </div>
        );
    } else {
        return null;
    }
}

export default ScheduledAtComponent;
