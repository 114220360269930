class Debouncer {
    controllerByKey: Map<string, AbortController> = new Map<string, AbortController>();
    timerByKey: Map<string, NodeJS.Timeout> = new Map<string, NodeJS.Timeout>();

    debounce(id: string, func: () => Promise<void>, timeout = 500) {
        const timer = this.timerByKey.get(id);

        if (timer) {
            console.log('canceled');
            clearTimeout(timer);
        }

        const newTimer = setTimeout(async () => {
            try {
                await func();
            } catch (e) {
                console.log('debuoncer', 'debouncer error: ', e, 'error');
            }
        }, timeout);
        this.timerByKey.set(id, newTimer);
    }
}

export default new Debouncer();
