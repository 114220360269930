import { PopupMessageStoreState } from 'stores/popup-message';
import { SuperpanelAPIError } from './../utils/errors';
import httpClient from './http-client';

export const reload = () => {
    window.location.reload();
};

export const errorHandler = (popupMessageStore: PopupMessageStoreState) => {
    httpClient.interceptors.response.use(
        response => {
            if (response && response.status >= 300) {
                const { message } = response.data;
                const error = new SuperpanelAPIError(message);
                popupMessageStore.throwError(error);
                return Promise.reject(error);
            }
            return response;
        },
        error => {
            popupMessageStore.throwError(error);
            return;
        }
    );
};

export default {
    errorHandler
};
