import React from 'react';
import Tooltip from 'components/Tooltip';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

function FieldValue({
    field,
    value,
    fieldTooltip,
    valueToolTip
}: {
    field: string;
    value: string;
    fieldTooltip: string | null;
    valueToolTip: string | null;
}) {
    return (
        <div className="flex gap-4">
            {fieldTooltip ? (
                <Tooltip
                    title={<div className="flex max-h-60 flex-col flex-wrap gap-2 overflow-auto">{fieldTooltip}</div>}
                    placement="top"
                >
                    <span className="flex items-center gap-1">
                        <span className="font-medium">{field}</span> <InformationCircleIcon className="h-4 w-4" />
                    </span>
                </Tooltip>
            ) : (
                <span className="font-medium">{field}</span>
            )}
            {valueToolTip ? (
                <Tooltip
                    title={<div className="flex max-h-60 flex-col flex-wrap gap-2 overflow-auto">{valueToolTip}</div>}
                    placement="top"
                >
                    <span className="flex items-center gap-1">
                        <span className="rounded text-blue-600">{value}</span>
                        <InformationCircleIcon className="h-4 w-4" />
                    </span>
                </Tooltip>
            ) : (
                <span className="rounded text-blue-600">{value}</span>
            )}
        </div>
    );
}

export default FieldValue;
