import React, { useEffect, useState } from 'react';
import getAiConfig, { AiConfig } from 'api/getAiConfig';
import Loader from 'components/Loader';
import AutomationRule from './AiConfig/AutomationRule';

function AiConfigComp() {
    const [aiConfig, setAiConfig] = useState<AiConfig | null>(null);
    const [isLoading, setIsloading] = useState<boolean>(false);

    useEffect(() => {
        const fetchAiConfig = async () => {
            // fetch ai config from api
            setIsloading(true);
            const aiConfig = await getAiConfig();
            setAiConfig(aiConfig);
            setIsloading(false);
        };
        fetchAiConfig();
    }, []);

    if (isLoading || aiConfig === null) {
        return (
            <div className="flex h-[100vh] w-[100vw] items-center justify-center">
                <Loader />
            </div>
        );
    }
    return (
        <div className="mb-6 flex flex-col gap-3">
            {aiConfig.nextStepRule.map(rule => {
                return (
                    <div key={JSON.stringify(rule)} className="rounded-lg border-2 border-gray-500 px-2 py-1">
                        <AutomationRule
                            nextStepRuleParameterDescription={aiConfig.nextStepRuleParameterDescription}
                            automationRule={rule}
                        />
                    </div>
                );
            })}
        </div>
    );
}

export default AiConfigComp;
