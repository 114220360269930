/* eslint-disable no-param-reassign */
import { ReactNode } from 'react';
import moment from 'moment';
import { mountStoreDevtool } from 'simple-zustand-devtools';
import { create } from 'zustand';
import { SuperpanelAPIError } from 'utils/errors';

export const UnexpectedError = 'An unexpected error happened, please try again or contact us';

export enum PopupMessageColour {
    RED = 'RED',
    YELLOW = 'YELLOW',
    BLUE = 'BLUE',
    GREEN = 'GREEN'
}

export interface PopupMessage {
    colour: PopupMessageColour;
    message: ReactNode;
    link: string | null;
}

export interface PopupMessageStoreState {
    isDisplayed: boolean;
    popupMessage: null | PopupMessage;
    popupTime: null | moment.Moment;
    setState: (newState: Partial<PopupMessageStoreState>) => PopupMessageStoreState;
    success: (message: string) => void;
    warning: (message: string) => void;
    error: (message: string) => void;
    throwError: (superpanelApiError: SuperpanelAPIError) => void;
}

const usePopupMessageStore = create<PopupMessageStoreState>((set, get) => ({
    isDisplayed: false,
    popupMessage: null,
    popupTime: null,
    // Do not copy/paste that method, this is needed because of animations
    setState: (newState: Partial<PopupMessageStoreState>) => {
        set(state => {
            return { ...state, ...newState };
        });
        return get();
    },
    success: (message: string) => {
        set(state => ({
            ...state,
            isDisplayed: true,
            popupMessage: { colour: PopupMessageColour.GREEN, message, link: null },
            popupTime: moment()
        }));
    },
    warning: (message: string) => {
        set(state => ({
            ...state,
            isDisplayed: true,
            popupMessage: { colour: PopupMessageColour.YELLOW, message, link: null },
            popupTime: moment()
        }));
    },
    error: (message: string) => {
        set(state => ({
            ...state,
            isDisplayed: true,
            popupMessage: { colour: PopupMessageColour.RED, message, link: null },
            popupTime: moment()
        }));
    },
    throwError: superpanelApiError => {
        set(state => ({
            ...state,
            isDisplayed: true,
            popupMessage: {
                colour: PopupMessageColour.RED,
                message: superpanelApiError.displayErrorMessage,
                link: superpanelApiError.link
            },
            popupTime: moment()
        }));
    }
}));

if (process.env.NODE_ENV === 'development') {
    mountStoreDevtool('PopupMessage', usePopupMessageStore);
}

export default usePopupMessageStore;
