import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from 'pages/Login';
import MustBeLoggedIn from 'components/MustBeLoggedIn';
import Sidebar from 'components/Sidebar';
import MainPage from 'pages/MainPage';
import Contact from 'pages/Contact';
import Transfers from 'pages/Transfers';
import { errorHandler } from 'api/handlers';
import usePopupMessageStore from 'stores/popup-message';
import PopupMessage from 'components/PopupMessage';
import Settings from 'pages/Settings';

function App() {
    const popupMessageStore = usePopupMessageStore();
    errorHandler(popupMessageStore);

    return (
        <BrowserRouter basename="/">
            <PopupMessage />
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route
                    path="/"
                    element={
                        <MustBeLoggedIn>
                            <Sidebar>
                                <MainPage />
                            </Sidebar>
                        </MustBeLoggedIn>
                    }
                />
                <Route
                    path="/contacts"
                    element={
                        <MustBeLoggedIn>
                            <Sidebar>
                                <MainPage />
                            </Sidebar>
                        </MustBeLoggedIn>
                    }
                />
                <Route
                    path="/contacts/:contactId"
                    element={
                        <MustBeLoggedIn>
                            <Sidebar>
                                <Contact />
                            </Sidebar>
                        </MustBeLoggedIn>
                    }
                />
                <Route
                    path="/transfers"
                    element={
                        <MustBeLoggedIn>
                            <Sidebar>
                                <Transfers />
                            </Sidebar>
                        </MustBeLoggedIn>
                    }
                />
                <Route
                    path="/settings"
                    element={
                        <MustBeLoggedIn>
                            <Sidebar>
                                <Settings />
                            </Sidebar>
                        </MustBeLoggedIn>
                    }
                />
            </Routes>
        </BrowserRouter>
    );
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
