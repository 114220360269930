import { User } from 'types/user';
import httpClient from './http-client';

export interface Response {
    user: User;
}

const getMe = async (): Promise<Response> => {
    const { data } = await httpClient.get<Response>('/api/me');
    return data;
};

export default getMe;
