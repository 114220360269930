import React from 'react';
import Switch from '@mui/material/Switch';
import Loader from 'components/Loader';
import updateContact from 'api/updateContact';

interface Props {
    data: { id: number; aiEnabled: boolean | null; aiEnabledReplySmsCall: boolean; intakeCompletionScore?: number };
    fieldName: string;
}

function AiEnabledButton(data: Props) {
    const field = data.fieldName;
    if (field === undefined || (field !== 'aiEnabled' && field !== 'aiEnabledReplySmsCall')) {
        console.log('Field is undefined');
        return null;
    }
    const [checked, setChecked] = React.useState<boolean | null>(data.data[field]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsLoading(true);
        setChecked(event.target.checked);
        await updateContact({
            contactId: data.data.id,
            [field]: event.target.checked
        });
        setIsLoading(false);
    };

    const disabled = field === 'aiEnabled' && data.data.intakeCompletionScore === 1 ? true : false;

    if (checked === null) {
        return <div className="">n/a</div>;
    }

    if (isLoading) {
        return (
            <div className="relative">
                <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
                    <Loader />
                </div>
            </div>
        );
    }

    return (
        <div>
            <Switch
                checked={checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
                disabled={disabled}
            />
        </div>
    );
}

export default AiEnabledButton;
