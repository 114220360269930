import httpClient from 'api/http-client';

export interface Response {
    result: string;
}

interface UpdateCompanyPayload {
    aiEnabled?: boolean;
    aiEnabledAutoTakeContact?: boolean;
    autoTakeContactRatio?: number;
}

const updateCompany = async (payload: UpdateCompanyPayload): Promise<Response> => {
    const { data } = await httpClient.post<Response>('/api/update-db-company', payload);
    return data;
};

export default updateCompany;
