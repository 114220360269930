import { User } from 'types/user';
import httpClient from './http-client';

export interface Response {
    user: User;
}

const login = async (loginPayload: { email: string; password: string }): Promise<Response> => {
    const { data } = await httpClient.post<Response>('/api/login', loginPayload);
    return data;
};

export default login;
