import React, { useState } from 'react';
import { CollectionInfo, ISlotWithId, TypeContactDetail } from 'api/get-contact-detail';
import { millionSecondsToDhmsString } from 'lib/seconds-to-days-hours-mins';
import { ArrowTopRightOnSquareIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import updateContactCollection from 'api/updateContactCollection';
import IntakeStatusSelect from 'pages/Contacts/IntakeStatusSelect';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

function ContactDetailBasic(props: { contactInfo: TypeContactDetail }) {
    let timeFromNow = '';
    if (props.contactInfo.generalInfo.nextStepAutomatic.scheduledAt) {
        timeFromNow = millionSecondsToDhmsString(
            props.contactInfo.generalInfo.nextStepAutomatic.scheduledAt - Date.now()
        );
        if (timeFromNow === '') {
            timeFromNow = 'now';
        }
    }

    const [fieldValues, setFieldValues] = useState<CollectionInfo[]>(props.contactInfo.collectionInfo);
    const [editingField, setEditingField] = useState<CollectionInfo | null>(null);
    const [initialField, setInitialField] = useState<CollectionInfo | null>(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const handleInputChange = (value: string | boolean | number | Date | null, key: keyof ISlotWithId) => {
        if (editingField) {
            setEditingField(prev => {
                if (prev) {
                    return { ...prev, [key]: value };
                }
                return prev;
            });
        }
    };

    // field value label puts on the dialog to remind the user what kind of value they should input
    const getValueLabel = () => {
        if (editingField) {
            if (editingField.dataType === 'number') {
                return 'Value (Number)';
            }
        }
        return 'Value';
    };

    // when clicking on the save button, the field value will be saved and the dialog will be closed
    const handleSave = async () => {
        if (editingField) {
            setFieldValues(prevValues =>
                prevValues.map(field => (field.slot_id === editingField.slot_id ? editingField : field))
            );

            await updateContactCollection({
                contactId: props.contactInfo.generalInfo.id,
                updateCollection: {
                    value: editingField.value,
                    state: editingField.state,
                    reason: editingField.reason,
                    msg_for_future: editingField.msg_for_future,
                    slot_id: editingField.slot_id
                }
            });
            setEditingField(null);
            setInitialField(null);
            setIsDialogOpen(false);
        }
    };

    const handleClose = () => {
        // preventing close the dialog when clicking outside if there is any changes
        if (hasChanges()) {
            return;
        }
        setEditingField(null);
        setInitialField(null);
        setIsDialogOpen(false);
    };

    // when clicking on the cancel button, the field value will be reset to the initial value and the dialog will be closed
    const handleCancel = () => {
        setEditingField(null);
        setInitialField(null);
        setIsDialogOpen(false);
    };

    // if there is any changes in the dialog, the save button will be enabled and can't be closed when clicking outside
    const hasChanges = () => {
        return JSON.stringify(editingField) !== JSON.stringify(initialField);
    };

    // render the input value based on the value data type
    const renderValueInput = (field: CollectionInfo) => {
        // if the field is from hubspot, the value will be changed on hubspot
        if (field.integration === 'hubspot') {
            const hubspotLink = `${props.contactInfo.generalInfo.hubspotUrl}/properties?search=${field.slot_id}`;
            return (
                <a
                    href={hubspotLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center space-x-2 border px-4 py-1 hover:text-blue-500"
                >
                    <span>{field.value === null ? '' : String(field.value)}</span>
                    <ArrowTopRightOnSquareIcon className="h-4 w-4" />
                    <span className="text-xs">(open hubspot to edit)</span>
                </a>
            );
        }

        // if the field data type is boolean, the value will be selected from the dropdown with true, false, and null
        if (field.dataType === 'boolean') {
            return (
                <select
                    value={field.value === null ? '' : String(field.value)}
                    onChange={e => {
                        if (e.target.value === '') {
                            handleInputChange(null, 'value');
                        } else if (e.target.value === 'true') {
                            handleInputChange(true, 'value');
                        } else if (e.target.value === 'false') {
                            handleInputChange(false, 'value');
                        }
                    }}
                    className="w-full items-center overflow-auto rounded-md border px-3 py-2"
                >
                    <option value="true">true</option>
                    <option value="false">false</option>
                    <option value="">null</option>
                </select>
            );
        }

        // if the field data type is number, the type is text to give the user option to input empty string, which will be converted to null, invalid number won't shown
        else if (field.dataType === 'number') {
            return (
                <input
                    type="text"
                    value={field.value === null ? '' : String(field.value)}
                    onChange={e => {
                        const inputValue = e.target.value.trim();
                        const newValue =
                            inputValue === '' ? null : isNaN(Number(inputValue)) ? null : Number(inputValue);
                        handleInputChange(newValue, 'value');
                    }}
                    className="w-full rounded border border-gray-300 p-2"
                />
            );
        }

        // if the field data type is datetime, the input type is datetime-local
        else if (field.dataType === 'datetime') {
            return (
                <div className="z-50">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <div className="mb-4">
                            <DateTimePicker
                                value={
                                    typeof field.value === 'string' ? dayjs(new Date(field.value).toISOString()) : null
                                }
                                onChange={newValue => {
                                    if (newValue !== null && newValue.isValid()) {
                                        handleInputChange(newValue.toISOString(), 'value');
                                    } else {
                                        handleInputChange(null, 'value');
                                    }
                                }}
                            />
                        </div>
                    </LocalizationProvider>
                </div>
            );
        }

        // if the field data type is custom enum, the value will be selected from the dropdown with the enum values with null option
        else if (typeof field.dataType === 'string' && field.dataType.includes('enum')) {
            // Extract the enum values using regex
            const enumMatch = field.dataType.match(/\[(.*?)\]/);
            const enumValues = enumMatch ? enumMatch[1].split(',').map(value => value.trim().replace(/'/g, '')) : [];

            return (
                <select
                    value={field.value === null ? '' : String(field.value)}
                    onChange={e => {
                        if (e.target.value === '') {
                            handleInputChange(null, 'value');
                        } else {
                            handleInputChange(e.target.value, 'value');
                        }
                    }}
                    className="w-full items-center overflow-auto rounded-md border px-3 py-2"
                >
                    <option value=""></option>
                    {enumValues.map(option => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
            );
        }

        // if the field data type is string, use textarea
        else {
            return (
                <textarea
                    value={field.value === null ? '' : String(field.value)}
                    onChange={e => {
                        if (e.target.value === '') {
                            handleInputChange(null, 'value');
                        } else {
                            handleInputChange(e.target.value, 'value');
                        }
                    }}
                    className="w-full rounded border border-gray-300 p-2"
                />
            );
        }
    };

    return (
        <div className="flex h-[calc(100vh-8rem)] w-full flex-col gap-3 divide-y-2 divide-lime-400 overflow-auto px-2">
            <div className="divide-7 flex flex-col gap-2">
                <div className="border-2 p-2">
                    <div>
                        <span>intake status: </span>
                        <IntakeStatusSelect
                            data={{
                                id: props.contactInfo.generalInfo.id,
                                intakeStatus: props.contactInfo.generalInfo.intakeStatus
                            }}
                            intakeStatusArray={props.contactInfo.generalInfo.intakeStatusArray}
                        />
                    </div>
                </div>
                <div className="border-2 p-2">
                    <div>
                        {props.contactInfo.generalInfo.intakeStatusReason !== null && (
                            <>
                                <p>Reason: </p>
                                <p className="max-h-32 overflow-auto whitespace-pre">
                                    {props.contactInfo.generalInfo.intakeStatusReason}
                                </p>
                            </>
                        )}
                    </div>
                </div>

                <div className={`border-2 p-2`}>
                    {props.contactInfo.generalInfo.aiEnabled === true ? (
                        <div>
                            {props.contactInfo.generalInfo.nextStep === 'automatic-follow-up' && (
                                <div>
                                    <div>
                                        {props.contactInfo.generalInfo.nextStep === 'automatic-follow-up' &&
                                            `Contact Friendly Hours (${props.contactInfo.generalInfo.contactFriendlyHours.timezone || 'UTC'}) ${props.contactInfo.generalInfo.contactFriendlyHours.start}:00-${props.contactInfo.generalInfo.contactFriendlyHours.end}:00`}
                                    </div>
                                    <div className="bg-gray-100 text-green-600">
                                        next step:{' '}
                                        {props.contactInfo.generalInfo.nextStepAutomatic.nextStep === 'doSms' &&
                                            'send a sms'}
                                        {props.contactInfo.generalInfo.nextStepAutomatic.nextStep === 'doCall' &&
                                            'make a call'}
                                        {props.contactInfo.generalInfo.nextStepAutomatic.nextStep === 'doNothing' &&
                                            'No next step'}
                                    </div>
                                    {props.contactInfo.generalInfo.nextStepAutomatic.reason !== null && (
                                        <div className="">
                                            reason: {props.contactInfo.generalInfo.nextStepAutomatic.reason}
                                        </div>
                                    )}
                                    {props.contactInfo.generalInfo.nextStepAutomatic.scheduledAt !== null ? (
                                        <div className="">time: in {timeFromNow}</div>
                                    ) : (
                                        <div className="">time: now</div>
                                    )}
                                </div>
                            )}
                            {props.contactInfo.generalInfo.nextStep === 'schedule-call' && (
                                <div>
                                    <div className="bg-gray-100 text-green-600">
                                        next step: make a call in {timeFromNow}
                                    </div>
                                    {props.contactInfo.generalInfo.nextStepReason !== null && (
                                        <div className="">
                                            reason: {props.contactInfo.generalInfo.nextStepAutomatic.reason}
                                        </div>
                                    )}
                                </div>
                            )}
                            {props.contactInfo.generalInfo.nextStep === 'schedule-sms' && (
                                <div>
                                    <div className="bg-gray-100 text-green-600">
                                        next step: send an sms in {timeFromNow}
                                    </div>
                                    {props.contactInfo.generalInfo.nextStepReason !== null && (
                                        <div className="">
                                            reason: {props.contactInfo.generalInfo.nextStepAutomatic.reason}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    ) : (
                        <div>
                            {props.contactInfo.generalInfo.intakeCompletionScore === 1
                                ? `next step: intake 100% done`
                                : `next step: ai disabled`}
                        </div>
                    )}
                </div>
            </div>
            <table className="w-full">
                <tr>
                    <th className="w-10">Field Name</th>
                    <th className="w-15">Value</th>
                    <th className="w-10">State</th>
                    <th className="w-15">reason</th>
                    <th className="w-15">Message For Future</th>
                </tr>
                <tbody>
                    {fieldValues.map(field => (
                        <React.Fragment key={field.slot_id}>
                            <tr className="group pb-4">
                                <td className="relative mr-4 border border-slate-300 px-1">
                                    {field.slot_id.split('_').join(' ')}
                                    <PencilSquareIcon
                                        className="absolute right-1 top-1 hidden h-5 w-5 cursor-pointer text-orange-500 group-hover:block"
                                        onClick={() => {
                                            setIsDialogOpen(true);
                                            setEditingField(field);
                                            setInitialField(field);
                                        }}
                                    />
                                </td>
                                <td className="border border-slate-300 pl-1">{`${field.value === null ? '' : field.dataType === 'datetime' && typeof field.value === 'string' ? new Date(field.value) : field.value}`}</td>
                                <td className="border border-slate-300 pl-1">{field.state}</td>
                                <td className="border border-slate-300 pl-1">{field.reason}</td>
                                <td className="border border-slate-300 pl-1">{field.msg_for_future}</td>
                            </tr>
                            <Dialog
                                open={isDialogOpen && editingField?.slot_id === field.slot_id}
                                onClose={handleClose}
                            >
                                <DialogTitle className="p-2 text-center font-bold">
                                    {field.slot_id.split('_').join(' ')}
                                </DialogTitle>
                                <DialogContent>
                                    <div className="p-2">
                                        <label className="mb-2 block">{getValueLabel()}</label>
                                        {editingField && renderValueInput(editingField)}
                                    </div>
                                    <div className="p-2">
                                        <label className="mb-2 block">State</label>
                                        <select
                                            value={editingField?.state}
                                            onChange={e => handleInputChange(e.target.value, 'state')}
                                            className="w-full items-center overflow-auto rounded-md border px-3 py-2"
                                        >
                                            <option value="complete">{`COMPLETE (ai won't ask this question)`}</option>
                                            <option value="need-more-data">NEED-MORE-DATA (ai will ask)</option>
                                            <option value="n/a">N/A (ai will skip)</option>
                                            <option value="initial">{`INITIAL (ai won't ask as user has given the initial info)`}</option>
                                        </select>
                                    </div>
                                    <div className="p-2">
                                        <label className="mb-2 block">Reason</label>
                                        <textarea
                                            className="w-full rounded border border-gray-300 p-2"
                                            value={editingField?.reason || ''}
                                            onChange={e => {
                                                if (e.target.value === '') {
                                                    handleInputChange(null, 'reason');
                                                } else {
                                                    handleInputChange(e.target.value, 'reason');
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="p-2">
                                        <label className="mb-2 block">Message For Future</label>
                                        <textarea
                                            className="w-full rounded border border-gray-300 p-2"
                                            value={editingField?.msg_for_future || ''}
                                            onChange={e => {
                                                if (e.target.value === '') {
                                                    handleInputChange(null, 'msg_for_future');
                                                } else {
                                                    handleInputChange(e.target.value, 'msg_for_future');
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="mx-2 text-sm text-red-600">
                                        {`Make sure you have the right 'state', 'reason', and 'message for future' for AI (even if you update the value in hubspot, you must manually update these values)`}
                                    </div>

                                    <div className="flex justify-end p-2">
                                        <button className="mr-2 rounded bg-gray-300 px-4 py-2" onClick={handleCancel}>
                                            Cancel
                                        </button>
                                        <button
                                            className={`rounded px-4 py-2 text-white ${hasChanges() ? 'bg-blue-500' : 'bg-gray-300'}`}
                                            onClick={handleSave}
                                            disabled={!hasChanges()}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </DialogContent>
                            </Dialog>
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
            <div>
                ai jobs (what ai is doing/has done):
                <table className="w-full border-2 p-2">
                    <tr>
                        <th className="w-15">job id</th>
                        <th className="w-15">name</th>
                        <th className="w-10">status</th>
                        <th className="w-15">scheduled</th>
                    </tr>
                    {props.contactInfo.jobInfo.map(job => (
                        <tr key={job.id} className="pb-4">
                            <td className="mr-4 border border-slate-300 px-1 align-top">{job.id}</td>
                            <td className="border border-slate-300 pl-1">{job.name}</td>
                            <td className="border border-slate-300 pl-1">{job.status}</td>
                            <td className="border border-slate-300 pl-1">{job.scheduled}</td>
                        </tr>
                    ))}
                </table>
            </div>
        </div>
    );
}

export default ContactDetailBasic;
