import { NORMAL_AI_STATUS } from 'api/getContacts';
import React, { useState, useEffect } from 'react';

interface IntakeStatusFilterProps {
    intakeStatusArray: string[];
    onFilterChange: (filterValue: string | null) => void;
    currentFilter: string | null; // Add prop for current filter value
}

const IntakeStatusFilter: React.FC<IntakeStatusFilterProps> = ({
    onFilterChange,
    currentFilter,
    intakeStatusArray
}) => {
    const [selectedValue, setSelectedValue] = useState<string | null>(null);

    useEffect(() => {
        setSelectedValue(currentFilter);
    }, [currentFilter]);

    const handleApplyFilter = () => {
        onFilterChange(selectedValue);
    };

    const handleResetFilter = () => {
        setSelectedValue(null);
        onFilterChange(null);
    };
    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        if ('' === event.target.value) {
            setSelectedValue(null);
            onFilterChange(null);
        } else {
            setSelectedValue(event.target.value);
            onFilterChange(event.target.value);
        }
    };

    return (
        <div className="intake-status-filter">
            <select value={selectedValue || ''} onChange={handleChange} className="rounded border px-2 py-1">
                <option value="">(Select Intake Status)</option>
                <option value={NORMAL_AI_STATUS}>{NORMAL_AI_STATUS}</option>
                {intakeStatusArray.map(status => (
                    <option key={status} value={status}>
                        {status.split('-').join(' ')}
                    </option>
                ))}
            </select>
            <button onClick={handleResetFilter} className="ml-2 rounded bg-red-500 px-2 py-1 text-white">
                Reset
            </button>
        </div>
    );
};

export default IntakeStatusFilter;
