import React, { useEffect, useState } from 'react';
import { Box, Button, Modal } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';

export interface FilterModelItem {
    colId: string;
    label: string;
    filterType: 'text' | 'date';
    type: 'contains' | 'inRange';
    filter?: string | null;
    dateFrom?: number | null;
    dateTo?: number | null;
}

type props = {
    filterModel: FilterModelItem[];
    onFilterChange: (colId: string, datetimeFrom: number | null, datetimeTo: number | null) => void;
};

function Filter(props: props) {
    const [curColId, setCurColId] = useState<string | null>(null);
    const [dateRange, setDateRange] = useState<{ start: Dayjs | null; end: Dayjs | null }>({ start: null, end: null });
    const [error, setErrpr] = useState<null | string>(null);
    // open modal to filter by date
    const handleOpenModal = (colId: string) => {
        setCurColId(colId);
    };

    const handleCloseModal = () => {
        setCurColId(null);
    };

    const handleClearModal = () => {
        setDateRange({ start: null, end: null });
    };

    const handleApplyFilter = () => {
        if (curColId !== null) {
            props.onFilterChange(curColId, dateRange.start?.valueOf() || null, dateRange.end?.valueOf() || null);
        }
        handleCloseModal();
    };

    useEffect(() => {
        if (curColId) {
            const startDt = props.filterModel.find(filter => filter.colId === curColId)?.dateFrom;
            if (startDt) {
                setDateRange(prev => ({ ...prev, start: dayjs(startDt) }));
            }
            const endDt = props.filterModel.find(filter => filter.colId === curColId)?.dateTo;
            if (endDt) {
                setDateRange(prev => ({ ...prev, end: dayjs(endDt) }));
            }
        }
    }, [curColId, props.filterModel]);

    useEffect(() => {
        if (dateRange.start === null && dateRange.end !== null) {
            setErrpr('missing start date');
        } else if (dateRange.start !== null && dateRange.end === null) {
            setErrpr('missing end date');
        } else if (dateRange.start && dateRange.start.isAfter(dateRange.end)) {
            setErrpr('start date should not be later than end date');
        } else {
            setErrpr(null);
        }
    }, [dateRange]);

    return (
        <>
            <div className="flex grow items-center gap-2">
                <span className="text-md font-medium">Filter by </span>
                {props.filterModel.map(filter => {
                    let dateFrom = '';
                    if (filter.dateFrom) {
                        dateFrom = new Date(filter.dateFrom).toISOString().split('T')[0];
                    }
                    let dateTo = '';
                    if (filter.dateTo) {
                        dateTo = new Date(filter.dateTo).toISOString().split('T')[0];
                    }
                    let dateFromTo = '';
                    if (dateFrom || dateTo) {
                        dateFromTo = `${dateFrom} - ${dateTo}`;
                    }
                    return (
                        <button
                            key={filter.colId}
                            className={`flex gap-1 rounded ${filter.dateFrom || filter.dateTo ? 'border-2 border-blue-500' : 'border'} border-gray-400 px-1 text-gray-600`}
                            onClick={() => handleOpenModal(filter.colId)}
                        >
                            {filter.label} <span className="text-green-600">{dateFromTo}</span>
                        </button>
                    );
                })}
            </div>
            <Modal open={curColId !== null} onClose={handleCloseModal}>
                <Box
                    sx={{
                        p: 4,
                        backgroundColor: 'white',
                        margin: 'auto',
                        marginTop: '10%',
                        width: '300px',
                        borderRadius: '8px'
                    }}
                >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <div className="mb-4">
                            <DateTimePicker
                                label="Start Date"
                                value={dateRange.start}
                                onChange={newValue => setDateRange(prev => ({ ...prev, start: newValue }))}
                            />
                        </div>
                        <div className="mb-4">
                            <DateTimePicker
                                label="End Date"
                                value={dateRange.end}
                                onChange={newValue => setDateRange(prev => ({ ...prev, end: newValue }))}
                            />
                        </div>
                    </LocalizationProvider>
                    <div className="flex flex-col gap-1">
                        <div className="text-sm text-red-600"> {error && <span>{error}</span>}</div>
                        <div className="flex justify-end gap-2">
                            <Button variant="outlined" color="secondary" onClick={handleClearModal}>
                                clear
                            </Button>
                            <Button variant="outlined" color="secondary" onClick={handleCloseModal}>
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleApplyFilter}
                                disabled={error !== null}
                            >
                                Apply
                            </Button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
}

export default Filter;
