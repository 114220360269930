import httpClient from './http-client';

export interface Response {
    message: string;
}

const createCallTask = async (payload: { contactId: string }): Promise<void> => {
    await httpClient.post<Response>('/api/create-ai-call', payload);
};

export default createCallTask;
