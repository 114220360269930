import React, { useEffect, useState } from 'react';
import ContactDetail from './ContactDetail/ContactInfo';
import { Link, useParams } from 'react-router-dom';
import getContactDetail, { TypeContactDetail } from 'api/get-contact-detail';
import SpinLoader from 'components/ui/spinLoader';
import AiEnabledButton from './Contacts/AiEnabledButton';
import Hubspot from 'components/logos/hubspot';

function Contact() {
    const [selectedTab, setSelectedTab] = React.useState('tab1');
    const { contactId } = useParams();

    const [contactInfo, setContactInfo] = useState<TypeContactDetail | null>(null);
    const [isloading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchContactDetail = async () => {
            if (!contactId) {
                return;
            }
            setIsLoading(true);
            const contactInfo = await getContactDetail({
                contactId: contactId
            });
            setContactInfo(contactInfo);
            setIsLoading(false);
        };
        fetchContactDetail();
    }, []);

    if (isloading || contactInfo === null) return <SpinLoader className="mr-2 h-4 w-4 animate-spin" />;

    if (!contactId) {
        return <div>No contact ID Found</div>;
    }

    return (
        <div>
            <div className="m-3 flex items-center gap-6">
                <button
                    className={`rounded px-2 py-1 ${selectedTab === 'tab1' ? 'bg-blue-300' : 'bg-gray-200'}`}
                    value="tab1"
                    onClick={() => {
                        setSelectedTab('tab1');
                    }}
                >
                    Contact Detail
                </button>
                <button
                    className={`rounded px-2 py-1 ${selectedTab === 'tab2' ? 'bg-blue-300' : 'bg-gray-200'}`}
                    onClick={() => {
                        setSelectedTab('tab2');
                    }}
                >
                    Call Agenda
                </button>
                <div className="flex gap-2">
                    <Link
                        to={`${contactInfo.generalInfo.hubspotUrl}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center gap-2 hover:underline"
                    >
                        <Hubspot className="h-4 w-4" /> {contactInfo.generalInfo.hubspotContactId}
                    </Link>
                </div>
                <div className="flex items-center gap-1 space-x-2">
                    <span>Intake Completion</span>
                    <span className="underline">
                        {(contactInfo.generalInfo.intakeCompletionScore * 100).toFixed(0)}%
                    </span>
                </div>

                <div className="flex items-center space-x-2">
                    <span>AI follow ups</span>
                    <AiEnabledButton
                        data={{
                            id: contactInfo.generalInfo.id,
                            aiEnabled: contactInfo.generalInfo.aiEnabled,
                            aiEnabledReplySmsCall: contactInfo.generalInfo.aiEnabledReplySmsCall,
                            intakeCompletionScore: contactInfo.generalInfo.intakeCompletionScore
                        }}
                        fieldName="aiEnabled"
                    />
                    {!contactInfo.generalInfo.aiEnabled && (
                        <span className="text-blue-500">({contactInfo.generalInfo.aiDisabledReason})</span>
                    )}
                </div>
                <div className="flex items-center space-x-2">
                    <span>AI responses</span>
                    <AiEnabledButton
                        data={{
                            id: contactInfo.generalInfo.id,
                            aiEnabled: contactInfo.generalInfo.aiEnabled,
                            aiEnabledReplySmsCall: contactInfo.generalInfo.aiEnabledReplySmsCall
                        }}
                        fieldName="aiEnabledReplySmsCall"
                    />
                    {!contactInfo.generalInfo.aiEnabledReplySmsCall && (
                        <span className="text-blue-500">({contactInfo.generalInfo.aiDisabledReplySmsCallReason})</span>
                    )}
                </div>
            </div>
            <div className="m-3 w-full">
                <ContactDetail selectedTab={selectedTab} contactId={contactId} contactInfo={contactInfo} />
            </div>
        </div>
    );
}

export default Contact;
