import httpClient from 'api/http-client';
import { HubspotShortContact } from 'types/hubspot';

export interface Response {
    contacts: HubspotShortContact[];
    after: string | null;
}

const getHubspotContacts = async (searchPayload: {
    search: string | null;
    after: string | null;
}): Promise<Response> => {
    const { data } = await httpClient.post<Response>('/api/hubspot-contacts', searchPayload);
    return data;
};

export default getHubspotContacts;
