import { User } from 'types/user';
import httpClient from './http-client';

export interface Response {
    user: User;
}

const updateTransferLineStatus = async (updateTransferLineStatusPayload: {
    transferLineId: string;
}): Promise<Response> => {
    const { data } = await httpClient.post<Response>(
        '/api/update-transferLine-status',
        updateTransferLineStatusPayload
    );
    return data;
};

export default updateTransferLineStatus;
