import React from 'react';
import { SortModelItem } from 'stores/contact-table-config';

type props = {
    sortModels: SortModelItem[];
    onSortChange: (colId: string) => void;
};

function Sort(props: props) {
    return (
        <div className="flex grow items-center gap-2">
            <span className="text-md font-medium">Sort by </span>
            {props.sortModels.map(sort => (
                <button
                    key={sort.colId}
                    className={`flex gap-1 rounded ${sort.sort === null ? 'border' : 'border-2 border-blue-500'} border-gray-400 px-1 text-gray-600`}
                    onClick={() => props.onSortChange(sort.colId)}
                >
                    {sort.label}
                    {sort.sort === 'asc' && <span className="text-green-600">↑</span>}
                    {sort.sort === 'desc' && <span className="text-red-600">↓</span>}
                </button>
            ))}
        </div>
    );
}

export default Sort;
