import httpClient from './http-client';
import { Step } from 'types/ai';

export interface Response {
    result: Step[];
}

const getAgenda = async (): Promise<Response> => {
    const { data } = await httpClient.post<Response>('/api/get-agenda');
    return data;
};

export default getAgenda;
