import { ClassNames } from '@emotion/react';
import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
    data?: { hubspotContactId?: string };
}

function ViewButtonComponent({ data }: Props) {
    return (
        <Link
            to={`/contacts/${data?.hubspotContactId}`}
            target="_blank"
            rel="noopener noreferrer"
            className="h-20 rounded bg-blue-500 px-3 py-1 font-bold text-white hover:bg-blue-700"
        >
            View
        </Link>
    );
}

export default ViewButtonComponent;
