import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import useUserStore from 'stores/user';
import getMe from 'api/me';
import Loader from './Loader';

interface Props {
    children: JSX.Element;
}

function MustBeLoggedIn({ children }: Props): JSX.Element {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setIsError] = useState(false);
    const userStore = useUserStore();
    useEffect(() => {
        const fetchMe = async (): Promise<void> => {
            // api /me may throw error
            try {
                const { user } = await getMe();
                if (!user) {
                    setIsError(true);
                    setIsLoading(false);
                    return;
                }
                userStore.setState({ user });
            } catch (error) {
                console.log('error', error);
                setIsError(true);
            }
            setIsLoading(false);
        };
        fetchMe();
    }, []);

    if (error) {
        return <Navigate to="/login" />;
    }

    if (isLoading) {
        return (
            <div className="flex h-[100vh] w-[100vw] items-center justify-center">
                <Loader />
            </div>
        );
    }

    if (userStore.user !== null) {
        return children;
    }
    return <Navigate to="/login" />;
}

export default MustBeLoggedIn;
