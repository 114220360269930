import React from 'react';
import { TypeContactDetail } from 'api/get-contact-detail';
// import { Dialog, DialogContent, DialogHeader, DialogTitle } from 'components/ui/dialog';
import { Label } from 'components/ui/label';
import { IMessage } from 'types/ai';
import * as Dialog from '@radix-ui/react-dialog';
import { Link } from 'react-router-dom';

function AssistantMessageView(props: { message: IMessage }) {
    return (
        <div className="w-fit min-w-28 rounded bg-green-200 p-1 text-sm">
            <Label className="capitalize">{props.message.role}:</Label>
            <p>{props.message.content}</p>
        </div>
    );
}

function UserMessageView(props: { message: IMessage }) {
    return (
        <div className="flex justify-end">
            <div className="min-w-28 rounded bg-blue-200 p-1 text-sm">
                <Label className="capitalize">{props.message.role}:</Label>
                <p>{props.message.content}</p>
            </div>
        </div>
    );
}

function ContactDetailCall(props: { contactInfo: TypeContactDetail }) {
    const getDatetime = (timestamp: number) => {
        const date = new Date(timestamp);
        return date.toLocaleString();
    };

    return (
        <div className="flex h-[calc(100vh-9rem)] w-full flex-col gap-4 overflow-auto px-2">
            {props.contactInfo.callInfo.map(call => {
                return (
                    <Dialog.Root key={call.id}>
                        <div className="gap flex justify-between rounded-md border-2 p-2">
                            <div className="w-full">
                                <div className="flex items-center gap-1">
                                    <Label className="font-semibold capitalize">Call time:</Label>
                                    <p className="">{getDatetime(call.timestamp)}</p>
                                </div>
                                <div className="flex items-center gap-1">
                                    <Label className="font-semibold capitalize">Status:</Label>
                                    <p className="">{call.status}</p>
                                </div>
                                {call.summary && (
                                    <div className="items-center gap-1">
                                        <Label className="font-semibold capitalize">Summary:</Label>
                                        <p className="">{call.summary}</p>
                                    </div>
                                )}
                                <div className="flex w-full justify-around">
                                    <Dialog.Trigger>
                                        <button className="my-1 flex rounded-md border-2 p-1 hover:bg-gray-200">
                                            <div className="flex gap-2">
                                                <div>transcript</div>
                                            </div>
                                        </button>
                                    </Dialog.Trigger>
                                    <Link
                                        className="my-1 flex rounded-md border-2 p-1 hover:bg-gray-200"
                                        to={call.externalUrl || ''}
                                        target="_blank"
                                    >
                                        <div>{call.externalUrl ? 'hubspot' : 'N/A'}</div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <Dialog.Portal>
                            {/* conversation history */}
                            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
                            <Dialog.Content className="fixed left-1/2 top-1/2 max-h-[80vh] max-w-md -translate-x-1/2 -translate-y-1/2 overflow-auto rounded-md bg-white shadow">
                                <Dialog.Title className="p-2">Conversation</Dialog.Title>
                                <div>
                                    <div className="flex h-full flex-col justify-between gap-5">
                                        <div className="h-[calc(100%-3rem)]">
                                            <div className="flex h-[calc(100%-2rem)] flex-col gap-2 overflow-y-auto rounded border p-1">
                                                {call.conversations.map((c, i) => {
                                                    if (c.role === 'user') {
                                                        return <UserMessageView key={i} message={c} />;
                                                    } else if (c.role === 'assistant') {
                                                        return <AssistantMessageView key={i} message={c} />;
                                                    }
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Content>
                        </Dialog.Portal>
                    </Dialog.Root>
                );
            })}
        </div>
    );
}

export default ContactDetailCall;
