import React from 'react';
import Loader from 'components/Loader';
import { NORMAL_AI_STATUS } from 'api/getContacts';
import updateContactIntakeStatus from 'api/updateContactIntakeStatus';

interface Props {
    data: { id: number; intakeStatus: string | null };
    intakeStatusArray: string[];
}
function IntakeStatusSelect(data: Props) {
    const [intakeStatus, setIntakeStatus] = React.useState<string | null>(data.data.intakeStatus);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const handleChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newStatus = e.target.value === NORMAL_AI_STATUS ? null : e.target.value;
        setIsLoading(true);
        setIntakeStatus(newStatus);
        await updateContactIntakeStatus({
            contactId: data.data.id,
            intakeStatus: newStatus
        });
        setIsLoading(false);
    };

    if (isLoading) {
        return (
            <div className="relative">
                <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
                    <Loader />
                </div>
            </div>
        );
    }

    const getTextColor = (status: string | null) => {
        return status ? 'text-gray-500' : 'text-green-600';
    };

    return (
        <select
            value={intakeStatus || NORMAL_AI_STATUS}
            onChange={handleChange}
            className={`rounded border-2 border-gray-300 px-2 py-1 ${getTextColor(intakeStatus)}`}
        >
            <option value={NORMAL_AI_STATUS}>{NORMAL_AI_STATUS}</option>
            {data.intakeStatusArray.map((status: string) => (
                <option key={status} value={status}>
                    {status}
                </option>
            ))}
        </select>
    );
}

export default IntakeStatusSelect;
