import httpClient from 'api/http-client';
import { ISlotWithId } from './get-contact-detail';

export interface Response {
    result: string;
}

const updateContactCollection = async (payload: {
    contactId: number;
    updateCollection: ISlotWithId;
}): Promise<Response> => {
    const { data } = await httpClient.post<Response>('/api/update-db-contact-collection', payload);
    return data;
};

export default updateContactCollection;
