import React, { useEffect } from 'react';
import { Alert, AlertColor, Slide } from '@mui/material';
import usePopupMessageStore, { PopupMessageColour } from 'stores/popup-message';
import styles from './PopupMessage.module.css';

const COLOUR_TO_SEVERITY: Record<PopupMessageColour, AlertColor> = {
    [PopupMessageColour.RED]: 'error',
    [PopupMessageColour.YELLOW]: 'warning',
    [PopupMessageColour.BLUE]: 'info',
    [PopupMessageColour.GREEN]: 'success'
};

function PopupMessage(): JSX.Element {
    const popupMessageStore = usePopupMessageStore();
    const severity = popupMessageStore.popupMessage
        ? COLOUR_TO_SEVERITY[popupMessageStore.popupMessage.colour]
        : undefined;

    const hide = () => {
        if (popupMessageStore.popupMessage !== null) {
            // This prevents the flickering of the animation, the popup needs to be gone before updating the message.
            popupMessageStore.setState({ isDisplayed: false });
            setTimeout(() => {
                popupMessageStore.setState({ popupMessage: null });
            }, 100);
        }
    };

    useEffect(() => {
        if (popupMessageStore.popupTime) {
            const timeout = setTimeout(() => {
                hide();
            }, 10000);
            return () => clearTimeout(timeout);
        }
        return () => null;
    }, [popupMessageStore.popupTime]);

    return (
        <Slide direction="down" in={popupMessageStore.isDisplayed} mountOnEnter unmountOnExit>
            <Alert onClose={() => hide()} className={styles.alert} severity={severity}>
                {popupMessageStore.popupMessage?.message || ''}
                {popupMessageStore.popupMessage?.link && (
                    <div>
                        Click{' '}
                        <a
                            className="text-blue-500"
                            target="_blank"
                            rel="noreferrer"
                            href={popupMessageStore.popupMessage.link}
                        >
                            Manage Apps
                        </a>{' '}
                        to remove app.
                    </div>
                )}
            </Alert>
        </Slide>
    );
}

export default PopupMessage;
