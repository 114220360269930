import httpClient from 'api/http-client';

export type ContactState = {
    firstInteractionTime: number | null;
    lastInteractionTime: number | null;
    lastInteractionType: 'call' | 'sms' | null;
    isLastCallAnswered: boolean | null;
    isLastCallEnd: boolean | null;
    userEverReplySms: boolean | null;
    userEverAnswerCall: boolean | null;
    lastUserAnswerCallTime: number | null;
    lastUserSmsTime: number | null;
    lastUserInteractionTime: number | null;
    lastUserInteractionType: 'call' | 'sms' | null;
    userEverInteraction: boolean | null;
    numberOfCalls: number;
    numberOfAssistantSms: number;
    isSmsOnlyCollectionsMissing: boolean | null;
};

export type ContactStateOptional = {
    firstInteractionTime?: number | null;
    lastInteractionTime?: number | null;
    lastInteractionType?: 'call' | 'sms' | null;
    isLastCallAnswered?: boolean | null;
    isLastCallEnd?: boolean | null;
    userEverReplySms?: boolean | null;
    userEverAnswerCall?: boolean | null;
    lastUserAnswerCallTime?: number | null;
    lastUserSmsTime?: number | null;
    lastUserInteractionTime?: number | null;
    lastUserInteractionType?: 'call' | 'sms' | null;
    userEverInteraction?: boolean | null;
    numberOfCalls?: {
        operator: 'eq' | 'gt' | 'lt' | 'gte' | 'lte';
        value: number;
    };
    numberOfAssistantSms?: {
        operator: 'eq' | 'gt' | 'lt' | 'gte' | 'lte';
        value: number;
    };
    numberOfUserSms?: {
        operator: 'eq' | 'gt' | 'lt' | 'gte' | 'lte';
        value: number;
    };
    isSmsOnlyCollectionsMissing?: boolean | null;
};

type TimeUnit = 's' | 'm' | 'h' | 'd';
type TimeIntervalString = `${number}${TimeUnit}`;
type TimeIntervalContition = {
    timeToCheck: 'firstInteractionTime' | 'lastInteractionTime' | 'lastUserInteractionTime';
    startIntervalTime: TimeIntervalString | null;
    endIntervalTime: TimeIntervalString | null;
    type?: 'user-friendly' | 'days-different';
};

export type NextStepRule = {
    description: string;
    conditions: {
        timeIntervalConditions: TimeIntervalContition[];
    } & ContactStateOptional;
    do: {
        nextStep: 'doCall' | 'doSms' | 'doNothing' | 'doScheduleSms';
        type?: 'random';
    };
};

export type NextStepRuleParameterDescription = {
    [key in keyof ContactState]: {
        name: string;
        description: string;
    };
};

export interface AiConfig {
    nextStepRuleParameterDescription: NextStepRuleParameterDescription;
    nextStepRule: NextStepRule[];
}

const getAiConfig = async (): Promise<AiConfig> => {
    const { data } = await httpClient.post<AiConfig>('/api/get-ai-config');
    return data;
};

export default getAiConfig;
