import { User } from 'types/user';
import httpClient from './http-client';

export interface Response {
    user: User;
}

const sendSms = async (sendMessagePayload: { hubspotContactId: string; message: string }): Promise<Response> => {
    const { data } = await httpClient.post<Response>('/api/send-sms', sendMessagePayload);
    return data;
};

export default sendSms;
