import React from 'react';
import Tooltip from 'components/Tooltip';

interface Props {
    data: { nextStepDetailReason: string | null };
}

function ScheduledAtComponent({ data }: Props) {
    if (data.nextStepDetailReason) {
        return (
            <Tooltip
                title={
                    <div className="flex max-h-60 flex-col flex-wrap gap-2 overflow-auto">
                        {data.nextStepDetailReason}
                    </div>
                }
                placement="top"
            >
                <div className="max-w-30 overflow-hidden text-ellipsis">
                    {data.nextStepDetailReason.slice(0, 20)} ...
                </div>
            </Tooltip>
        );
    } else {
        return null;
    }
}

export default ScheduledAtComponent;
