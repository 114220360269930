import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
    data: { hubspotContactId?: string; hubspotUrl?: string };
}

function HubspotButtonComponent({ data }: Props) {
    if (data.hubspotUrl) {
        return (
            <div>
                <Link
                    to={`${data.hubspotUrl}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:underline"
                >
                    {data.hubspotContactId}
                </Link>
            </div>
        );
    } else {
        return data?.hubspotContactId;
    }
}

export default HubspotButtonComponent;
