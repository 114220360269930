import httpClient from 'api/http-client';
import { TransferLine } from 'types/transferLine';

export interface Response {
    transferLines: TransferLine[];
    after: string | null;
}

const getTransferLines = async (): Promise<Response> => {
    const { data } = await httpClient.post<Response>('/api/transfer-lines');
    return data;
};

export default getTransferLines;
