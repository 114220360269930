import React from 'react';
import { UserGroupIcon, PhoneArrowDownLeftIcon, Cog6ToothIcon } from '@heroicons/react/24/outline';
import SuperpanelIcon from './icons/SuperpanelIcon';
import { cn } from 'lib/utils';
import { useLocation } from 'react-router-dom';

const navigation = [
    {
        name: 'contacts',
        href: '/contacts',
        icon: UserGroupIcon,
        current: true
    },
    {
        name: 'transfers',
        href: '/transfers',
        icon: PhoneArrowDownLeftIcon,
        current: false
    }
];

const navigationBottom = [
    {
        name: 'settings',
        href: '/settings',
        icon: Cog6ToothIcon,
        current: false
    }
];

function Sidebar(props: { children: React.ReactNode }) {
    const location = useLocation();

    return (
        <div className="h-full">
            {/* Static sidebar for desktop */}
            <div className="fixed inset-y-0 left-0 z-50 flex w-20 flex-col overflow-y-auto border-r bg-slate-100">
                <div className="flex h-16 shrink-0 items-center justify-center">
                    <SuperpanelIcon className="h-10 w-auto" />
                </div>
                <nav className="mt-8 flex flex-1 flex-col">
                    <ul className="flex flex-col items-center gap-4 space-y-1">
                        {navigation.map(item => (
                            <li key={item.name}>
                                <a
                                    href={item.href}
                                    className={cn(
                                        location.pathname.includes(item.href)
                                            ? 'bg-blue-500 text-white'
                                            : 'bg-gray-300 text-white',
                                        'group flex gap-x-3 rounded-md p-3 text-sm font-semibold leading-6'
                                    )}
                                >
                                    <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                                    <span className="sr-only">{item.name}</span>
                                </a>
                            </li>
                        ))}
                    </ul>
                    <div className="flex-1"></div>
                    <ul className="mb-8 flex flex-col items-center gap-4 space-y-1">
                        {navigationBottom.map(item => (
                            <li key={item.name}>
                                <a
                                    href={item.href}
                                    className={cn(
                                        location.pathname.includes(item.href)
                                            ? 'bg-blue-500 text-white'
                                            : 'bg-gray-300 text-white',
                                        'group flex gap-x-3 rounded-md p-3 text-sm font-semibold leading-6'
                                    )}
                                >
                                    <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                                    <span className="sr-only">{item.name}</span>
                                </a>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>

            <main className="h-full pl-20">
                <div className="h-full px-8">{props.children}</div>
            </main>
        </div>
    );
}

export default Sidebar;
