import React, { useEffect, useState } from 'react';
import getHubspotContact from 'api/getHubspotContact';
import SpinLoader from 'components/ui/spinLoader';
import { useParams } from 'react-router-dom';
import { Step } from 'types/ai';
import { HubspotContactLong } from 'types/hubspot';
import getAgenda from 'api/get-agenda';

function CallAgenda() {
    const { contactId } = useParams();

    const [isloading, setIsLoading] = useState(false);
    const [contact, setContact] = useState<HubspotContactLong | null>(null);
    const [superpanelAgenda, setSuperpanelAgenda] = useState<Step[] | null>(null);

    useEffect(() => {
        const getContact = async () => {
            if (!contactId) {
                throw new Error('No contactId provided');
            }
            setIsLoading(true);
            const contactObj = await getHubspotContact({
                contactId,
                properties: ['firstname', 'lastname', 'phone']
            });
            setContact(contactObj);
            setIsLoading(false);
        };
        getContact();
    }, []);

    useEffect(() => {
        const getAgd = async () => {
            setIsLoading(true);
            const { result: agenda } = await getAgenda();
            setSuperpanelAgenda(agenda);
            setIsLoading(false);
        };
        getAgd();
    }, []);

    if (isloading) return <SpinLoader className="mr-2 h-4 w-4 animate-spin" />;

    return (
        <div className="flex h-[calc(100vh-8rem)] gap-4">
            <div className="flex w-full flex-col">
                <div className="h1/5 text-xs">
                    <pre>{JSON.stringify(contact, null, 2)}</pre>
                </div>
                <div className="mb-4 mt-4 h-4/5 max-h-full overflow-auto rounded-md border bg-white p-4">
                    <h1 className="text-xl font-semibold">Call Agenda</h1>
                    <div>
                        {superpanelAgenda &&
                            superpanelAgenda.map((agenda, index) => {
                                if (agenda.hidden) return null;
                                return (
                                    <div key={agenda.id}>
                                        <h3 className="text-lg font-semibold">Step {index + 1}</h3>
                                        <h4 className="font-semibold">Description: {agenda.description}</h4>
                                        {agenda.points.map(point => (
                                            <div className="" key={`${point.id}`}>
                                                <p className="text-sm font-semibold">- {point.id}</p>
                                                <p className="text-sm">&nbsp;&nbsp;&nbsp;&nbsp;{point.description}</p>
                                            </div>
                                        ))}
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CallAgenda;
