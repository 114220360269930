import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { ColDef, SizeColumnsToContentStrategy, GridOptions, SizeColumnsToFitGridStrategy } from 'ag-grid-community';
import Loader from 'components/Loader';
import type { DbContact } from '../../types/dbContact';
import IntakeStatusFilter from './IntakeStatusFilter';
import ViewButtonComponent from './ViewButtonComponent';
import AiEnabledButton from './AiEnabledButton';
import { Switch } from '@mui/material';
import updateCompany from 'api/updateCompany';
import HubspotButtonComponent from './HubspotButtonComponent';
import ScheduledAtComponent from './ScheduledAtComponent';
import ReasonComponent from './ReasonComponent';
import { CheckIcon, PhoneIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { ChatBubbleIcon } from '@radix-ui/react-icons';
import SpinLoader from 'components/ui/spinLoader';
import getContacts, { FilterModelItemInPayload } from 'api/getContacts';
import Filter, { FilterModelItem } from './SortAndFilter/Filter';
import Sort from './SortAndFilter/Sort';
import debouncer from 'lib/debouncer';
import IntakeStatusSelect from './IntakeStatusSelect';
import useContactTableConfigStore, { SortModelItem } from 'stores/contact-table-config';

function Contacts() {
    const [rowData, setRowData] = useState<DbContact[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const contactTableConfigStore = useContactTableConfigStore();

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [pageInput, setPageInput] = useState('');
    const [customFilter, setCustomFilter] = useState<string | null>(null);
    const [totalContacts, setTotalContacts] = useState(0);
    const [intakeStatusArray, setIntakeStatusArray] = useState<string[]>([]);
    const [aiEnabledCompany, setAiEnabledCompany] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [search, setSearch] = useState<null | string>(null);
    const [aiEnabledAutoTakeContact, setAiEnabledAutoTakeContact] = useState<boolean>(false);
    const [autoTakeContactRatio, setAutoTakeContactRatio] = useState<number>(0);
    const [columnDefs, setColumnDefs] = useState<ColDef[]>([
        { field: 'hubspotContactId', cellRenderer: HubspotButtonComponent, sortable: false },
        {
            headerName: 'name',
            sortable: false,
            width: 120,
            cellRenderer: function (data: {
                data: { firstname: string | null | undefined; lastname: string | null | undefined };
            }) {
                return (
                    <div>
                        {data.data.firstname ?? data.data.firstname} {data.data.lastname ?? data.data.lastname}
                    </div>
                );
            }
        },
        {
            headerName: 'First contact',
            sortable: false,
            field: 'firstInteractionAt',
            cellRenderer: function (data: {
                data: { firstInteractionAt: number | null; firstInteractionType: 'sms' | 'call' | null };
            }) {
                if (data.data.firstInteractionAt === null) {
                    return (
                        <div className="flex justify-center">
                            <div className="">n/a</div>
                        </div>
                    );
                } else {
                    const date = new Date(data.data.firstInteractionAt);
                    return (
                        <div className="flex h-10 items-center justify-center gap-1 text-sm">
                            {data.data.firstInteractionType === 'call' ? (
                                <PhoneIcon className="h-4 w-4" />
                            ) : (
                                <ChatBubbleIcon className="h-4 w-4" />
                            )}
                            {date.toLocaleString('en-US', {
                                month: 'short',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric'
                            })}
                        </div>
                    );
                }
            }
        },
        {
            headerName: 'Engaged',
            sortable: false,
            field: 'userEverInteraction',
            cellRenderer: function (data: { data: { userEverInteraction: boolean | null } }) {
                if (data.data.userEverInteraction === null) {
                    return (
                        <div className="flex justify-center">
                            <div className="">n/a</div>
                        </div>
                    );
                } else if (data.data.userEverInteraction) {
                    return (
                        <div className="flex h-10 items-center justify-center text-lg text-green-600">
                            <CheckIcon className="h-4 w-4" />
                        </div>
                    );
                } else {
                    return (
                        <div className="flex h-10 items-center justify-center text-lg text-red-600">
                            <XMarkIcon className="h-4 w-4" />
                        </div>
                    );
                }
            }
        },
        {
            headerName: 'Last Engagement',
            sortable: false,
            field: 'userLastInteractionAt',
            cellRenderer: function (data: {
                data: { userLastInteractionAt: number | null; userLastInteractionType: 'sms' | 'call' | null };
            }) {
                if (data.data.userLastInteractionAt === null) {
                    return (
                        <div className="flex justify-center">
                            <div className="">n/a</div>
                        </div>
                    );
                } else {
                    const date = new Date(data.data.userLastInteractionAt);
                    return (
                        <div className="flex h-10 items-center justify-center gap-1 text-sm">
                            {data.data.userLastInteractionType === 'call' ? (
                                <PhoneIcon className="h-4 w-4" />
                            ) : (
                                <ChatBubbleIcon className="h-4 w-4" />
                            )}
                            {date.toLocaleString('en-US', {
                                month: 'short',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric'
                            })}
                        </div>
                    );
                }
            }
        },
        {
            headerName: 'Intake Status',
            sortable: false,
            field: 'intakeStatus',
            cellRenderer: IntakeStatusSelect,
            cellRendererParams: { intakeStatusArray }
        },
        {
            headerName: 'Intake Completion',
            sortable: false,
            field: 'intakeCompletionScore',
            cellRenderer: function (data: { data: { intakeCompletionScore: number } }) {
                return `${(data.data.intakeCompletionScore * 100).toFixed(0)}%`;
            }
        },
        {
            headerName: 'ai follow ups',
            field: 'aiEnabled',
            sortable: false,
            cellRenderer: AiEnabledButton,
            cellRendererParams: { fieldName: 'aiEnabled' }
        },
        {
            headerName: 'ai responses',
            sortable: false,
            field: 'aiEnabledReplySmsCall',
            cellRenderer: AiEnabledButton,
            cellRendererParams: { fieldName: 'aiEnabledReplySmsCall' }
        },
        {
            headerName: 'next step',
            sortable: false,
            field: 'nextStepDetailNextStep',

            cellRenderer: function (data: {
                data: { nextStepDetailNextStep: string | null; intakeStatus: string | null };
            }) {
                if (data.data.nextStepDetailNextStep === null) {
                    return null;
                } else if (data.data.nextStepDetailNextStep === 'doCall') {
                    return (
                        <div className="flex h-10 w-10 items-center justify-center">
                            <PhoneIcon className="h-4 w-4 fill-gray-400 text-gray-400" />
                        </div>
                    );
                } else if (data.data.nextStepDetailNextStep === 'doSms') {
                    return (
                        <div className="flex h-10 w-10 items-center justify-center">
                            <ChatBubbleIcon className="h-4 w-4" />
                        </div>
                    );
                } else if (data.data.nextStepDetailNextStep === 'doNothing') {
                    return <div className="">No next step</div>;
                } else {
                    return <div>n/a</div>;
                }
            }
        },
        {
            headerName: 'scheduled at',
            sortable: false,
            field: 'nextStepDetailScheduledAt',
            cellRenderer: ScheduledAtComponent
        },
        {
            headerName: 'reason',
            sortable: false,
            field: 'nextStepDetailReason',
            cellRenderer: ReasonComponent
        },
        // { field: 'lastname', sortable: false },
        { field: 'phone', sortable: false },
        { field: 'email', sortable: false },
        {
            headerName: 'View',
            sortable: false,
            field: 'action',
            cellRenderer: ViewButtonComponent,
            pinned: 'right'
        }
    ]);
    const PAGESIZE = 20; // Define the page size

    const fetchContacts = async (
        page: number,
        pageSize: number,
        sortModel: SortModelItem[],
        filterModel: FilterModelItem[],
        customFilter: string | null
    ) => {
        setIsLoading(true);
        const sortFields = sortModel.filter(item => item.sort !== null).map(item => item.colId);
        const sortOrders: ('asc' | 'desc')[] = [];
        for (const item of sortModel) {
            if (item.sort !== null) {
                sortOrders.push(item.sort);
            }
        }

        // Combine custom filter with grid filter model
        const combinedFilterModel: FilterModelItemInPayload[] = filterModel.map(item => {
            return {
                colId: item.colId,
                filterType: item.filterType,
                type: item.type,
                filter: item.filter,
                dateFrom: item.dateFrom,
                dateTo: item.dateTo
            };
        });
        if (customFilter !== null) {
            combinedFilterModel.push({
                colId: 'intakeStatus',
                filterType: 'text',
                type: 'contains',
                filter: customFilter
            });
        }

        const res = await getContacts({
            search: search,
            page: page,
            pageSize: pageSize,
            sortFields,
            sortOrders,
            filters: combinedFilterModel
        });
        const contacts = res.result;

        const totalData = res.total;
        setTotalContacts(totalData);
        setTotalPages(Math.ceil(totalData / pageSize));
        setRowData(contacts);
        setAiEnabledCompany(res.aiEnabled);
        setIntakeStatusArray(res.intakeStatusArray);
        setColumnDefs(prev =>
            prev.map(item => {
                if (item.field === 'intakeStatus') {
                    return {
                        ...item,
                        cellRendererParams: { intakeStatusArray: res.intakeStatusArray }
                    };
                } else {
                    return item;
                }
            })
        );
        setAiEnabledAutoTakeContact(res.aiEnabledAutoTakeContact);
        setAutoTakeContactRatio(res.autoTakeContactRatio);
        setIsLoading(false);
    };

    useEffect(() => {
        fetchContacts(
            currentPage,
            PAGESIZE,
            contactTableConfigStore.config.sort,
            contactTableConfigStore.config.filter,
            customFilter
        );
    }, [currentPage, contactTableConfigStore.config.filter, customFilter]);

    useEffect(() => {
        fetchContacts(
            currentPage,
            PAGESIZE,
            contactTableConfigStore.config.sort,
            contactTableConfigStore.config.filter,
            customFilter
        );
    }, [contactTableConfigStore.config.sort]);

    if (isLoading) {
        return (
            <div className="flex h-[100vh] w-[100vw] items-center justify-center">
                <Loader />
            </div>
        );
    }

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePageInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPageInput(e.target.value);
    };

    const handlePageInputSubmit = () => {
        const pageNumber = parseInt(pageInput, 10);
        if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    const autoSizeStrategy: SizeColumnsToContentStrategy | SizeColumnsToFitGridStrategy = {
        // type: 'fitCellContents'
        type: 'fitGridWidth',
        defaultMinWidth: 150,
        columnLimits: [
            {
                colId: 'firstInteractionAt',
                minWidth: 160
            },
            {
                colId: 'userLastInteractionAt',
                minWidth: 160
            },
            {
                colId: 'aiEnabled',
                minWidth: 120
            },
            {
                colId: 'aiEnabledReplySmsCall',
                minWidth: 120
            },
            {
                colId: 'intakeCompletionScore',
                minWidth: 160
            }
        ]
    };

    const gridOptions: GridOptions = {
        autoSizeStrategy: autoSizeStrategy,
        enableCellTextSelection: true,
        ensureDomOrder: true,
        // defaultColDef: defaultColDef
        getRowStyle: function (params) {
            if (params.data.aiEnabled === false || params.data.intakeCompletionScore === 1) {
                return { 'background-color': '#DCDCDC' };
            }
            return undefined;
        }
    };

    const handleIntakeStatusFilterChange = (filterValue: string | null) => {
        setCustomFilter(filterValue);
    };

    const onAiEnabledChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsLoading(true);
        await updateCompany({ aiEnabled: event.target.checked });
        setAiEnabledCompany(event.target.checked);
        setIsLoading(false);
    };

    const onAiEnabledAutoTakeContactChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsLoading(true);
        await updateCompany({ aiEnabledAutoTakeContact: event.target.checked });
        setAiEnabledAutoTakeContact(event.target.checked);
        setIsLoading(false);
    };

    const onAutoTakeContactRatioChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        let ratio = parseInt(event.target.value, 10);
        if (isNaN(ratio)) {
            ratio = 0;
        }
        console.log('ratio', ratio);
        if (ratio < 0) ratio = 0;
        if (ratio > 100) ratio = 100;
        if (ratio !== autoTakeContactRatio) {
            debouncer.debounce(
                `update-auto-take-new-contact-ratio`,
                async () => {
                    await updateCompany({ autoTakeContactRatio: ratio });
                },
                1000
            );
        }
        setAutoTakeContactRatio(ratio);
    };

    const handleSearch = async () => {
        setIsSubmitting(true);
        setCurrentPage(1); // Reset page to 1 when searching
        await fetchContacts(
            currentPage,
            PAGESIZE,
            contactTableConfigStore.config.sort,
            contactTableConfigStore.config.filter,
            customFilter
        );
        setIsSubmitting(false);
    };

    return (
        <div className="h-[calc(100vh-8rem)] w-full">
            <div className="mb-4 flex items-center justify-between">
                <div className="justify-left flex items-center gap-4">
                    <span className="">Total Contacts: {totalContacts}</span>
                    <div className="flex items-center gap-1 rounded-md border border-gray-400 px-2">
                        <span className="font-medium text-blue-400">AI enabled (company level):</span>
                        <Switch
                            checked={aiEnabledCompany}
                            onChange={onAiEnabledChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </div>
                    {aiEnabledCompany && (
                        <div className="flex items-center justify-center gap-4 rounded-md border border-gray-400 px-2">
                            <div className="flex items-center gap-1">
                                <span className="font-medium text-blue-400">AI take new contacts:</span>
                                <Switch
                                    checked={aiEnabledAutoTakeContact}
                                    onChange={onAiEnabledAutoTakeContactChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </div>
                            <div className="flex items-center gap-1">
                                <span className="font-medium text-blue-400">ratio:</span>
                                <input
                                    type="number"
                                    value={autoTakeContactRatio}
                                    onChange={onAutoTakeContactRatioChange}
                                    className={`h-7 w-20 rounded border px-2 py-1 text-center ${aiEnabledAutoTakeContact && autoTakeContactRatio > 0 ? 'bg-green-300' : 'bg-gray-200'}`}
                                    min="0"
                                    max="100"
                                />
                                <span>%</span>
                            </div>
                        </div>
                    )}

                    <div className="">
                        <div>
                            <div className="flex items-center gap-2">
                                <div className="flex h-10 items-center rounded-md border border-gray-400 px-2 py-1">
                                    <input
                                        value={search || ''}
                                        type="text"
                                        placeholder="Search for contacts"
                                        onChange={e => {
                                            if (e.target.value === '') {
                                                setSearch(null);
                                            } else {
                                                setSearch(e.target.value);
                                            }
                                        }}
                                        onKeyDown={async e => {
                                            if (e.key === 'Enter') {
                                                await handleSearch();
                                            }
                                        }}
                                    />
                                </div>
                                <button
                                    type="button"
                                    className="rounded-md border border-gray-300 bg-gray-100 px-2 py-1 text-gray-800"
                                    disabled={isSubmitting}
                                    onClick={handleSearch}
                                >
                                    {isSubmitting && <SpinLoader className="mr-2 h-4 w-4 animate-spin" />}
                                    Search
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-end">
                    <IntakeStatusFilter
                        onFilterChange={handleIntakeStatusFilterChange}
                        currentFilter={customFilter}
                        intakeStatusArray={intakeStatusArray}
                    />
                </div>
            </div>
            <div className="mb-4 flex items-center">
                <Sort
                    sortModels={contactTableConfigStore.config.sort}
                    onSortChange={(colId: string) => {
                        const items = contactTableConfigStore.config.sort.filter(item => item.colId === colId);
                        if (items.length > 0) {
                            const item = items[0];
                            if (item.sort === 'asc') {
                                contactTableConfigStore.updateSort({ ...item, sort: 'desc' });
                            } else if (item.sort === 'desc') {
                                contactTableConfigStore.updateSort({ ...item, sort: null });
                            } else {
                                contactTableConfigStore.updateSort({ ...item, sort: 'asc' });
                            }
                        }
                    }}
                />
            </div>
            <div className="mb-4 flex items-center">
                <Filter
                    filterModel={contactTableConfigStore.config.filter}
                    onFilterChange={(colId: string, datetimeFrom: number | null, datetimeTo: number | null) => {
                        const items = contactTableConfigStore.config.filter.filter(item => item.colId === colId);
                        if (items.length > 0) {
                            const item = items[0];
                            contactTableConfigStore.updateFilter({
                                ...item,
                                dateFrom: datetimeFrom,
                                dateTo: datetimeTo
                            });
                        }
                    }}
                />
            </div>
            <div className="h-[calc(100vh-18rem)]">
                <div className="ag-theme-quartz" style={{ height: '100%', width: '100%' }}>
                    <AgGridReact rowData={rowData} columnDefs={columnDefs} gridOptions={gridOptions} />
                </div>
            </div>

            <div className="mt-4 flex items-center justify-end">
                <button
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                    className="mr-2 rounded bg-blue-500 px-4 py-2 text-white disabled:bg-gray-300"
                >
                    Prev Page
                </button>
                <span className="px-4 py-2">
                    Page {currentPage} of {totalPages}
                </span>
                <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    className="ml-2 rounded bg-blue-500 px-4 py-2 text-white disabled:bg-gray-300"
                >
                    Next Page
                </button>
                <input
                    type="number"
                    value={pageInput}
                    onChange={handlePageInputChange}
                    className="ml-4 w-20 rounded border px-2 py-1" // Adjusted width to make it smaller
                    placeholder="Go"
                />
                <button onClick={handlePageInputSubmit} className="ml-2 rounded bg-green-500 px-4 py-2 text-white">
                    Go
                </button>
            </div>
        </div>
    );
}

export default Contacts;
