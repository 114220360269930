import React, { useEffect, useState } from 'react';
import updateTransferLineStatus from 'api/updateTransferLineStatus';
import Loader from 'components/Loader';
import { Link } from 'react-router-dom';
import { TransferLine } from 'types/transferLine';
import getTransferLines from 'api/getTransferLines';

function Transfers() {
    const [transferLines, setTransferLines] = useState<TransferLine[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchTransferLines = async () => {
        setIsLoading(true);
        const res = await getTransferLines();
        setTransferLines(res.transferLines);
        setIsLoading(false);
    };

    useEffect(() => {
        fetchTransferLines();
        const interval = setInterval(async () => {
            const res = await getTransferLines();
            setTransferLines(res.transferLines);
        }, 3000);
        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, []);

    function formatTimestamp(timestamp: number) {
        const date = new Date(timestamp);
        return date.toLocaleString();
    }

    async function handleCallingIdClick(transferLineId: string) {
        await updateTransferLineStatus({ transferLineId });
    }

    if (isLoading) {
        return (
            <div className="flex h-[100vh] w-[100vw] items-center justify-center">
                <Loader />
            </div>
        );
    }

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Transfer Lines</h1>
                    <p className="mt-2 text-sm text-gray-700">A list of all the transfer lines</p>
                </div>
                <div className="mt-4 sm:ml-16 sm:mt-0">{/* <Search /> */}</div>
            </div>
            <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                        >
                                            Transfer Line
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Status
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Calling HubspotId
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Last Called HubspotId
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                    {transferLines.map(line => (
                                        <tr key={line.id}>
                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                {line.agentNumber}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                {line.status}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm font-bold text-red-600">
                                                <Link
                                                    to={`/contacts/${line.hubspotContactId}`}
                                                    className="underline hover:text-red-800"
                                                    onClick={() => handleCallingIdClick(line.id)}
                                                >
                                                    {line.hubspotContactId}
                                                </Link>
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                <Link
                                                    to={`/contacts/${line.lastCallHubspotId}`}
                                                    className="text-blue-600 underline hover:text-blue-800"
                                                >
                                                    {line.lastCallHubspotId}
                                                </Link>
                                                <div className="text-xs text-gray-400">
                                                    {formatTimestamp(line.lastCallTime)}
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Transfers;
